<template>
    <div>
        <div class="mb-2 diagonal pb-8 pt-0 " >
            <div class="row">
                    <div class="col"><h3>Zoznam faktúr</h3></div>
            </div>
        </div>

            <div class="row">
                <div class="col">
                    <div class="card shadow">
                        <div class="card-header border-0">
                            <div class="row align-items-center">

                                <div class="col text-right">

                    <template>
                        <a-button type="secondary" ><i class="fas fa-cogs pr-1"></i> Nastavenia</a-button>
                        <a-button type="primary" class="ml-2"><i class="fas fa-plus-square pr-1"></i> Nová faktúra</a-button>
                    </template>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <a-table
                                    :columns="columns"
                                    :dataSource="data"
                                    :pagination="pagination"
                                    size="small"
                                    :loading="loading"
                                    :rowKey="record => record.id"
                                    class="table-flush">
                                <div
                                        slot="filterDropdown"
                                        slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
                                        style="padding: 8px"
                                >
                                    <a-input
                                            v-ant-ref="c => searchInput = c"
                                            :placeholder="`Search ${column.dataIndex}`"
                                            :value="selectedKeys[0]"
                                            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                                            @pressEnter="() => handleSearch(selectedKeys, confirm)"
                                            style="width: 188px; margin-bottom: 8px; display: block;"
                                    />
                                    <a-button
                                            type="primary"
                                            @click="() => handleSearch(selectedKeys, confirm)"
                                            icon="search"
                                            size="small"
                                            style="width: 90px; margin-right: 8px"
                                    >Search
                                    </a-button
                                    >
                                    <a-button @click="() => handleReset(clearFilters)" size="small" style="width: 90px"
                                    >Reset
                                    </a-button
                                    >
                                </div>
                                <a-icon
                                        slot="filterIcon"
                                        slot-scope="filtered"
                                        type="search"
                                        :style="{ color: filtered ? '#108ee9' : undefined }"
                                />
                                <template slot="customRender" slot-scope="text">
      <span v-if="searchText">
        <template
                v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
        >
          <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
          >{{fragment}}</mark
          >
          <template v-else>{{fragment}}</template>
        </template>
      </span>
                                    <template v-else
                                    >{{text}}
                                    </template
                                    >
                                </template>
                                <template slot="user_role" slot-scope="user">
                                    <a class="text-left text-lowercase" :href="'role/'+ role"
                                       v-for="(role,index) in JSON.parse(user.type_id)"> {{roleops[role]}}<span
                                            v-if="index+1 < JSON.parse(user.type_id).length">, </span></a>
                                </template>
                                <template slot="products" slot-scope="products">
                                    <div v-for="(product,ind) in products" :class="'product item-' + ind">

                                        <div v-if="product.type !== 'voucher'">
                                            <h4 :class="'type-' + product.type">{{product.title}} </h4>
                                            <p v-if="product.tv">{{product.tv}} voucher</p>
                                            <p v-else-if="product.type !== 'product'">
                                                {{product.body_value.replace(/<[^>]*>?/gm, '')}}</p>
                                        </div>
                                        <div v-else>
                                            <a-tag  v-if="product.tv === 'pobytovy'" color="volcano" :key="product.tv">     pobytový voucher</a-tag>
                                            <a-tag  v-if="product.tv === 'wellness'" color="geekblue" :key="product.tv"> wellness voucher</a-tag>
                                            <a-tag  v-if="product.tv === 'namieru'" color="green" :key="product.tv"> pobyt na mieru</a-tag>
                                        </div>
                                    </div>
                                </template>
                                <template slot="prices" slot-scope="price">
                                    {{Number(price).toFixed(2) + ' €'}}
                                </template>
                                <template slot="date" slot-scope="datedata">
                                    <span>{{formatDate(datedata)}}</span>
                                </template>
                                <template slot="FN" slot-scope="user,record,index">
                                   {{new Date().getFullYear()}}02{{parseInt(200-index)}}
                                </template>
                                <template slot="name" slot-scope="user">
                                    <a-button icon="file-pdf"/>

                                </template>
                            </a-table>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>
<script>

    import {dataService} from "../_services";
    import {Popconfirm} from "ant-design-vue"
    import {Button, Input, Icon, Tag} from 'ant-design-vue';

    import moment from 'moment'

    export default {
        name: "getinvoices",
        components: {
            "a-popconfirm": Popconfirm,
            "a-button": Button,
            "a-icon": Icon,
            "a-tag": Tag,
            "a-input": Input
        },
        data() {
            return {
                data: [],
                searchText: '',
                searchInput: null,
                roleops: [],
                colorsto: JSON.parse(localStorage.colors),
                loading: false,
                pagination: {
                    defaultPageSize: 25
                },
                columns: [
                    {
                        title: 'Číslo objednávky',
                        dataIndex: 'ID',
                        key: 'name',
                        width: '20%',
                        scopedSlots: {
                            filterDropdown: 'filterDropdown',
                            filterIcon: 'filterIcon',
                            customRender: 'customRender',
                        },
                        onFilter: (value, record) => {
                            if (typeof record.name != 'undefined' && record.name) {
                                return record.name.toString().toLowerCase().includes(value.toLowerCase());
                            }
                        },
                        onFilterDropdownVisibleChange: visible => {
                            if (visible) {
                                setTimeout(() => {
                                    this.searchInput.focus();
                                }, 0);
                            }
                        },
                    },
                       {
                        title: 'Firma',
                        key: 'firma',
                        dataIndex: 'Nazov',
                    },
                    {
                        title: 'Číslo faktúry',
                        dataIndex: 'Cislo',
                    },
                    {
                        title: 'Suma',
                        dataIndex: 'Suma00',
                        key: 'price',
                        width: '10%',
                        scopedSlots: {customRender: 'prices'},
                    },
                    {
                        align: 'right',
                        scopedSlots: {customRender: 'name'},
                    }
                ],
            };
        },

        mounted() {
            dataService.axiosFetch("roles").then(results => {

                results.forEach(obj => {
                    var iid = obj.id;
                    var vval = obj.title;
                    this.roleops[iid] = vval;


                });
            });
            this.$nextTick(() => {
                this.fetch();

            })

        },
        methods: {
            formatDate: function (dt) {
                return moment.unix(dt).format('D.M.YYYY H:mm')

            },
            handleSearch(selectedKeys, confirm) {
                confirm();
                this.searchText = selectedKeys[0];
                console.log(this.searchText);
            },

            handleReset(clearFilters) {
                clearFilters();
                this.searchText = '';
            },
            fetch() {
                this.loading = true;
                dataService.axiosFetch(this.$options.name).then(results => {
                    this.data = results;
                    this.loading = false;

                });
            }

        },
    };
</script>

<style>
    ul.ant-pagination {
        margin-top: 0;
        margin-bottom: 1em;
    }

    .highlight {
        background-color: rgb(255, 192, 105);
        padding: 0px;
    }

    .product {
        width: 100%;
        clear:both
    }

    .product h4 {
        margin: 0;
        font-size: 12px;
    }

    .product h4.type-product {
        font-size: 12px;
    }

    .product h4.type-voucher {
        display:none
    }

    .product h4 span {
        float: right;
        font-size: 12px;
        padding-top: 5px;
    }

    .product p {
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 2px;
        color: #000000;

    }


    .product:not(.item-0) h4 {
        padding-top: 2px;
    }


    .ant-table-pagination.ant-pagination {
        margin-bottom: 40px;
        margin-right: 20px;
    }

    .ant-pagination5-item {
        border: 0;
    }

    .ant-table {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        margin-bottom: 20px;
    }

    .ant-pagination5-item a, .ant-pagination5-prev a, .ant-pagination5-next a, .ant-pagination5-jump-prev a, .ant-pagination5-jump-next a {
        -webkit-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        border: 0;
    }

    .ant-pagination5-item a, .ant-pagination5-prev a, .ant-pagination5-next a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 0;
        margin: 0 3px;
        border-radius: 50% !important;
        width: 36px;
        height: 36px;
        font-size: 0.875rem;
    }


    .ant-pagination5-item.ant-pagination5-item-active a {
        z-index: 1;
        color: #fff;
        background-color: #5e72e4;
        border-color: #5e72e4;
    }

    .ant-pagination5-item-link.ant-pagination5-disabled a {
        color: #8898aa;
        pointer-events: none;
        cursor: auto;
        background-color: #fff;
        border-color: #dee2e6;
    }

</style>
